/* global itemViewModuleComponent */
class CustomItemViewModuleComponent extends itemViewModuleComponent {

    getTemplate() {
        return `<div class="card">
                  <div class="tag-item-module" v-if="item.NewItem || item.Outstanding" >
                    <h6 class="tag-item-new" v-if="item.NewItem">{{tr('New Item')}}</h6>
                    <h6  class="tag-item-outstanding"  v-if="item.Outstanding">{{tr('Outstanding')}} </h6>
                  </div>
                  <div class="discount" v-if="discount && item.CrossedOutPrice" >
                    <discountComponent :item="item" :currentItemPrice="currentItemPrice"/>
                  </div>
                  <div class="favorite-icon" v-if="$store.getters.getSession!=null" >
                      <favoriteBtnComponent :itemCode="item.Code"/>
                  </div>
                  <template v-if="$store.getters.getSetting && $store.getters.getSetting.DemoPhotos">
                    <div class="position-relative">
                        <router-link :to="'/view/'+encodeToURL(item.Code)">
                            <div class="card-image item-detail-modal" >
                                <itemSliderComponent :key="UIComponentID+'slide-1'+item.Code" :item="item" :stockFlag="$store.getters.showStock" :container="true" :imgNumber="0"></itemSliderComponent>
                                <template v-if="item.images && item.images.length > 1">
                                    <itemSliderComponent :key="UIComponentID+'slide-2'+item.Code" :item="item" :stockFlag="$store.getters.showStock" :container="true" :imgNumber="1"></itemSliderComponent>
                                </template>
                            </div>
                            <!--div v-if="$store.getters.getSession!=null" class="add-to-cart-options">
                                <span>Agregar al Carrito</span>
                            </div-->
                        </router-link>
                        <div v-if="$store.getters.getSession==null" class="add-to-cart-login">
                            <router-link to="/login">
                                <span>Registrate para agregar a tu carrito</span>
                            </router-link>
                        </div>
                    </div>
                  </template>
                  <div class="card-body">
                    <h5 class="card-title">
                        <p class="card-text item-name">{{item.Name}}</p>
                        <p v-if="$store.getters.getSession!=null && showStock" class="card-text item-name d-none">Stock {{item.stockQty}}</p>
                    </h5>
                    <template v-if="$store.getters.getSetting.ShowPriceByDefault || $store.getters.getIsLogin">
                        <priceComponent :item="item"></priceComponent>
                    </template>
                    <div v-if="$store.getters.getSession!=null" class="form-row mt-3">
                      <template v-if="alertMsg">
                            <div class="alert alert-danger" role="alert">{{tr(alertMsg)}}</div>
                      </template>
                      <div class="form-group col-8">
                          <template v-if="controlStock">
                              <template v-if="item.Qty>0">
                                  <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="item.Qty" type="number">
                              </template>
                              <template v-else>
                                <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="maxItemInCar" type="number" disabled aria-describedby="withOutStockHelpBlock"  >
                                <small id="withOutStockHelpBlock" class="form-text text-muted">
                                      {{tr("Without Stock")}}
                                </small>
                              </template>
                          </template>
                          <template v-else>
                              <input :ref="'carCant-'+item.Code" v-model="Qty" class="form-control" min="1" :max="maxItemInCar"type="number" :disabled="maxItemInCar<1">
                          </template>
                      </div>
                      <div class="col-4 text-center" :disabled="maxItemInCar<1">
                          <div class="cart-button" :title="tr('Add To Basket')" :class="{'disabled': !canShopItem}" @click.stop="canShopItem ? ItemComponentAddToCar(item) : null" >
                              <span class="icon"><i class="fa-icon fas fa-cart-plus"></i></span>
                          </div>
                      </div>
                    </div>
                </div>
            </div>`;
    }
}

CustomItemViewModuleComponent.registerComponent();
